import { dateFormatter, dateFormate } from "@/helpers/formatters";
export default {
  defaultColDef: {
    animateRows: true,
    sortable: true,
    resizable: true,
    unSortIcon: true
  },
  columnDefs: [
    {
      headerName: "Store ID",
      field: "storeID",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      filter: "agTextColumnFilter",
      pinned: "left"
    },

    {
      headerName: "Corp Tier",
      field: "corporateTier",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Supl DC",
      field: "dc",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Region",
      field: "region",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "District",
      field: "district",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "State",
      field: "state",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "City",
      field: "city",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Zip Code",
      field: "zip",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Admin Index",
      field: "indexValueAdmin"
    },
    {
      headerName: "Admin Ref Store",
      field: "referenceStoreIDAdmin"
    },
    {
      headerName: "Index",
      field: "indexValueUser",
      editable: true,
      valueSetter: function(params) {
        params.newValue = Number(params.newValue);
        if (
          typeof params.newValue === "number" &&
          !isNaN(params.newValue) &&
          params.newValue > 0
        ) {
          params.data.indexValueUser = params.newValue;
        } else {
          params.data.indexValueUser = params.oldValue;
        }
      },
      cellStyle: param => {
        if (param.value < 0)
          return { "background-color": "#F77B65", border: "1px solid #F4F6F8" };
        return { "background-color": "#F4F6F8", border: "1px solid #F4F6F8" };
      }
    },
    {
      headerName: "Reference Store",
      field: "referenceStoreIDUser",
      editable: true,
      cellStyle: param => {
        return { "background-color": "#F4F6F8", "border-color": "#F4F6F8" };
      }
    }
  ]
};
