<template>
  <div v-if="getAdminSettingsData.data">
    <vue-element-loading
      width="200"
      :active="getLoaderOnReset"
      spinner="bar-fade-scale"
      text="Resetting data..."
    />
    <div style="margin-top: 2rem">
      <div align="right">
        <i
          v-if="getModifiedDates"
          id="updatedDates"
          class="fa fa-info-circle"
          style="margin-right:1rem; font-size:18px;"
        ></i>
      </div>
      <b-card>
        <div slot="header">
          <strong>Store Index & Reference Store Mapping</strong>
        </div>
        <div>
          <data-table
            :data="getAdminSettingsData.data"
            :count="getAdminSettingsData.data.length"
            :colDefs="settingsColDef"
            :firstDataRendered="true"
            @onUpdateDcLevelData="updateDcLevelData"
            @cellValueChanged="cellValueChanged"
            @selectedItems="v => selectedItems = v"
            @updatedSuccesfully="massUpdateTargetCells"
          >
            <template slot-scope="props">
              Actions:
              <span>
                <i
                  v-if="!selectedItems.length"
                  v-b-tooltip.hover
                  title="Please select Store ID"
                  class="fas fa-paint-brush cursor-not-allowed"
                  style="margin-right:1rem"
                ></i>
                <i
                  v-if="selectedItems.length"
                  title="Multi Apply Store Index & Reference Store Mapping"
                  class="fas fa-paint-brush cursor-pointer paintBrush"
                  style="margin-right:1rem"
                  id="massUpdateAdmin1"
                ></i>
              </span>
              <span>
                <!-- <b-button
                v-if="!selectedItems.length"
                variant="link"
                style="cursor: not-allowed; border:none !important;margin-bottom: 7px;margin-left: -12px;"
                >Clear</b-button>-->

                <b-button
                  variant="success"
                  @click="onReset"
                  style="margin-right:1rem;background:rgb(115, 189, 0)"
                >Reset</b-button>
                <i
                  id="resetInfo"
                  title="The 'Reset' feature restores fields to the 'last saved' values."
                  class="fa fa-info-circle cursor-pointer"
                  style="margin-right:1rem; font-size:18px;"
                ></i>
                <b-popover
                  target="resetInfo"
                  triggers="click"
                  :show.sync="popoverShow3"
                  placement="auto"
                  container="updateContainer"
                  ref="popover3"
                  @show="onShow"
                >
                  <!-- <div v-on-click-outside="close"> -->
                  <div>
                    The 'Reset' feature restores fields to the 'last saved' values.
                  </div>
                </b-popover>
              </span>
              <span>
                <b-button
                  v-if="isAdmin == 'true'"
                  title="Set Poisson Distribution"
                  style="margin-right:1rem;background:rgb(115, 189, 0)"
                  variant="success"
                  id="adminPoisson"
                >Set Poisson Index</b-button>
              </span>
              <span style="margin-right:1rem; margin-top: 1rem;">
                Admin Values&nbsp;
                <toggle-button
                  style="padding-top:0.15rem;"
                  ref="AdminToggle"
                  :color="{checked: '#000000', unchecked: '#000000', disabled: '#CCCCCC'}"
                  :margin="3"
                  :font-size="1"
                  :width="35"
                  :height="15"
                  v-model="toggleFlag"
                  @change="invokeToggleMethod(props.onUpdateAdminIndexStore)"
                />&nbsp;User Values
              </span>
              <span>
                <b-button class="btn-download" @click="onDownloadClick" download>
                  Download
                  <i class="fa fa-download" style="padding-left:0.25rem;"></i>
                </b-button>
              </span>
              <span>
                <b-button
                  class="btn-download"
                  style="margin-left: 5px;"
                  @click="onUploadClick"
                  download
                >
                  Upload
                  <i class="fa fa-upload" style="padding-left:0.25rem;"></i>
                </b-button>
              </span>
              <div id="adminContainer">
                <b-popover
                  v-if="selectedItems.length"
                  target="massUpdateAdmin1"
                  triggers="click"
                  :show.sync="popoverShow"
                  placement="auto"
                  container="adminContainer"
                  ref="popover"
                  @show="onShow"
                >
                  <!-- <div v-on-click-outside="close"> -->
                  <template slot="title">
                    <b-button
                      class="close"
                      aria-label="Close"
                      @click="popoverShow=false"
                      style="color: #FFFFFF;"
                    >
                      <span class="d-inline-block" aria-hidden="true">&times;</span>
                    </b-button>
                    <b-row>
                      <b-col
                        style="padding-right:0; padding-top: 0.3rem;"
                        cols="11"
                      >Multi Apply Store Index & Reference Store Mapping</b-col>
                    </b-row>
                  </template>

                  <div>
                    <div>
                      <b-form-group>
                        <b-row class="py-1">
                          <b-col sm="4">
                            <label style="margin-top: 0.5rem;">Index:</label>
                          </b-col>
                          <b-col sm="8">
                            <b-form-input
                              placeholder="Index"
                              type="text"
                              width="50%"
                              :state="null"
                              v-model="massIndex"
                            />

                            <!-- <input/> -->
                          </b-col>
                        </b-row>
                        <b-row class="my-1">
                          <b-col sm="4">
                            <label style="margin-top: 0.5rem;">Reference Store:</label>
                          </b-col>
                          <b-col sm="8">
                            <multiselect
                              style="width: 100%;"
                              v-model="selectedRefStore"
                              :options="optionsForRefStoreSelection"
                              :clear-on-select="true"
                              :show-labels="false"
                              :searchable="true"
                              label="value"
                              open-direction="bottom"
                              @select="onSelectedRefStoreSelection"
                              placeholder="Type to search"
                              track-by="value"
                            ></multiselect>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="selectedRefStore && selectedRefStore.value === 'Other Stores'"
                          class="my-1"
                        >
                          <b-col sm="4">
                            <!-- <label style="margin-top: 0.5rem;">Reference Store:</label> -->
                          </b-col>
                          <b-col sm="8">
                            <multiselect
                              style="width: 100%;"
                              v-model="massRefStore"
                              :options="storeArr"
                              :clear-on-select="true"
                              :show-labels="false"
                              :searchable="true"
                              :loading="isLoading"
                              label="value"
                              open-direction="bottom"
                              @search-change="getStoreList"
                              placeholder="Select Stores"
                              track-by="value"
                            ></multiselect>
                      <b-form-text>To remove selection, click on the dropdown and select the red highlighted option.</b-form-text>
                          </b-col>
                        </b-row>
                      </b-form-group>
                      <b-button
                        @click="onApplyClicked1(props.onAdminUpdate)"
                        size="sm"
                        style="background:rgb(115, 189, 0); color:white;"
                        :disabled="((selectedRefStore && selectedRefStore.value === 'Other Stores') && massRefStore && massIndex) || ((selectedRefStore && selectedRefStore.value === 'Other Stores') && massRefStore) || (selectedRefStore && selectedRefStore.value === 'Own Stores') || ((selectedRefStore && selectedRefStore.value === 'Own Stores') && massRefStore && massIndex)  || (massIndex && !selectedRefStore)? false : true"
                      >Apply</b-button>
                    </div>
                  </div>
                </b-popover>
              </div>
              <div id="updateContainer1">
                <b-popover
                  v-if="true"
                  target="adminPoisson"
                  triggers="click"
                  :show.sync="popoverShow2"
                  placement="auto"
                  container="updateContainer1"
                  ref="popover2"
                  @show="onShow"
                >
                  <!-- <div v-on-click-outside="close"> -->
                  <template slot="title">
                    <b-button
                      class="close"
                      aria-label="Close"
                      @click="popoverShow2=false"
                      style="color: #FFFFFF;"
                    >
                      <span class="d-inline-block" aria-hidden="true">&times;</span>
                    </b-button>
                    <b-row>
                      <b-col
                        style="padding-right:0; padding-top: 0.3rem;"
                        cols="8"
                      >Set Poisson Index</b-col>
                    </b-row>
                  </template>
                  <div>
                    <div>
                      <b-form @submit="onSubmit">
                        <b-row>
                          <b-col sm="3">
                            <label style="margin-top: 0.5rem;">Poisson Distribution:</label>
                          </b-col>
                          <b-col sm="5">
                            <b-form-input
                              placeholder="Index"
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              width="100%"
                              required
                              v-model="poissonVal"
                            />
                          </b-col>
                          <b-col sm="2">
                            <b-button
                              size="sm"
                              style="background:rgb(115, 189, 0)"
                              type="submit"
                            >Apply</b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="12">
                            <div
                              style="color: red"
                            >Note* - The Poisson value set here will reflect as the default value for every user. However, this setting will not immediately reflect for those users who already have open projects that are in step 3 or beyond.</div>
                          </b-col>
                        </b-row>
                      </b-form>
                    </div>
                  </div>
                </b-popover>
              </div>
              <div id="updateContainer">
                <b-popover
                  v-if="getModifiedDates"
                  target="updatedDates"
                  triggers="click"
                  :show.sync="popoverShow1"
                  placement="auto"
                  container="updateContainer"
                  ref="popover1"
                  @show="onShow"
                >
                  <!-- <div v-on-click-outside="close"> -->
                  <div>
                    <div>
                      Last Admin Who Updated: {{getModifiedDates.name}}
                      <br />
                      Last Admin Update: {{getModifiedDates.lastModifiedOnAdmin}}
                      <br />
                      Last User Update: {{getModifiedDates.lastModifiedOnUser}}
                    </div>
                  </div>
                </b-popover>
              </div>
            </template>
          </data-table>
        </div>
        <b-form>
          <div style="display:flex;">
            <b-form-text
              style="flex:7"
            >Note* - All allocations will only use the Index & Reference Store values in the last two columns (the user-editable fields) after they have been saved.</b-form-text>
            <b-button
              v-if="isAdmin == 'true'"
              style=" margin:0.5rem;flex:2;background:rgb(115, 189, 0);color:white"
              @click="pushToAllUsers()"
            >Save & Push To All Users</b-button>
            <b-button
              style=" margin:0.5rem 0;flex:1;background:rgb(115, 189, 0);color:white"
              @click="saveAdminData()"
            >Save</b-button>
          </div>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DataTable from "@/components/DataTable";
import multiselect from "vue-multiselect";
import { isEqual, cloneDeep } from "lodash";
import settingsColDef from "@/constants/tableDefinitions/settingsColDef";
var handler = function(e) {
  var confirmationMessage = "Your unsave data will lost.";
  let tab = e || window.event;
  tab.returnValue = confirmationMessage;
  return confirmationMessage;
};
export default {
  components: {
    DataTable,
    multiselect
  },
  data() {
    return {
      optionsForRefStoreSelection: [
        { value: "Own Stores" },
        { value: "Other Stores" }
      ],
      selectedRefStore: null,
      massOwnRefStores: false,
      poissonVal: 0,
      finalPoissonVal: 0,
      getModifiedDates: {
        lastAdminWhoModified: "assd",
        lastModifiedOnUser: "12/4/19",
        lastModifiedOnAdmin: "12/4/19"
      },
      toggleFlag: true,
      settingsColDef,
      disableApply: true,
      popoverShow: false,
      popoverShow1: false,
      popoverShow2: false,
      popoverShow3: false,
      massIndex: null,
      massRefStore: null,
      selectedItems: [],
      storeArr: [],
      isLoading: false,
      fileSelector: null,
      isAdmin: false,
      gridApi: null,
      userIndexRefStore: {},
      popoverShow2: false
    };
  },
  methods: {
    ...mapActions({
      updatePoissonDistribution: "CreateAllocation/updatePoissonDistribution",
      adminSettingsData: "CreateAllocation/adminSettingsData",
      updateAdminSettingsData: "CreateAllocation/updateAdminSettingsData",
      downloadStoreIndex: "CreateAllocation/downloadStoreIndex",
      uploadAdminStoreIndex: "CreateAllocation/uploadAdminStoreIndex",
      findStoreIDsByStoreIDPrefix:
        "CreateAllocation/findStoreIDsByStoreIDPrefix",
      FetchAdminMetaData: "CreateAllocation/FetchAdminMetaData",
      fetchPoissonDistribution: "CreateAllocation/fetchPoissonDistribution"
    }),
    ...mapMutations({
      setLoader: "CreateAllocation/setLoader",
      setJsonData: "CreateAllocation/setJsonData",
      setTempJsonData: "CreateAllocation/setTempJsonData"
    }),
    onSelectedRefStoreSelection(val) {
      if (val.value === "Own Stores") {
        this.massOwnRefStores = true;
      }
    },
    onReset() {
      this.adminSettingsData({ onReset: true });
      // this.FetchAdminMetaData()
      //   .then(res => {
      //     this.getModifiedDates = res.data;
      //   })
      //   .catch(err => {
          // console.log(err);
      //   });
      // this.fetchPoissonDistribution();

      this.$gtag.event('Clicked_StoreIndexRefrenceStoreMappingResetButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Store Index Refrence Store mapping Reset Button',
      })
    },
    onSubmit(evt) {
      evt.preventDefault();
      let tempVal = this.poissonVal;
      this.updatePoissonDistribution({
        poissonDistribution: tempVal
      }).then(resp => {
        if (resp.status) {
          this.fetchPoissonDistribution();
        }
      });
      this.popoverShow2 = false;

      this.$gtag.event('Clicked_PoisonIndexApplyButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Poison Index Apply button',
      })
    },
    clearAll() {
      this.storeArr = [];
    },
    getStoreList(val) {
      if (val != "") {
        this.isLoading = true;
        this.findStoreIDsByStoreIDPrefix({
          storeIDPrefix: val
        }).then(resp => {
          this.isLoading = false;
          this.storeArr = resp.storeIDs.map(ele => {
            return { value: ele };
          });
        });
      }
    },
    invokeToggleMethod(updateCells) {
      let tempObj = {};
      if (this.toggleFlag == false) {
        this.userIndexRefStore = {};
        this.getAdminSettingsData.data.forEach((ele, index) => {
          tempObj[ele.storeID] = {
            ["referenceStoreIDUser"]: ele.referenceStoreIDUser,
            ["indexValueUser"]: ele.indexValueUser
          };
          ele["referenceStoreIDUser"] = ele.referenceStoreIDAdmin;
          ele["indexValueUser"] = ele.indexValueAdmin;
        });
        this.userIndexRefStore = tempObj;

        this.$gtag.event('Clicked_AdminValueToggleButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click Admin value toggele button',
      })
      } else if (
        Object.keys(this.userIndexRefStore).length &&
        this.toggleFlag == true
      ) {
        this.getAdminSettingsData.data.forEach((ele, index) => {
          ele["referenceStoreIDUser"] = this.userIndexRefStore[ele.storeID][
            "referenceStoreIDUser"
          ];
          ele["indexValueUser"] = this.userIndexRefStore[ele.storeID][
            "indexValueUser"
          ];
        });

        this.$gtag.event('Clicked_UserValueTogglebutton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click User value toggle button',
      })
      }
      updateCells();
    },
    close() {
      this.popoverShow = false;
    },
    saveAdminData() {
      let tempArr = [];
      let tempObj = {};
      this.getAdminSettingsData.data.forEach(ele => {
        tempObj = {};
        (tempObj["storeID"] = ele.storeID),
          (tempObj["indexValue"] = ele.indexValueUser),
          (tempObj["referenceStoreID"] = ele.referenceStoreIDUser);
        tempArr.push(tempObj);
      });
      this.$refs["AdminToggle"].toggled = true;
      this.userIndexRefStore = {};
      this.toggleFlag = true;
      this.updateAdminSettingsData({
        data: tempArr,
        all: false
      }).then(resp => {
        this.adminSettingsData();
      });

      this.$gtag.event('Clicked_StoreIndexRefrenceStoreMappingSaveButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click Store Index Refrence Store mapping Save button',
      })
    },
    pushToAllUsers(evt) {
      let tempArr = [];
      let tempObj = {};
      this.getAdminSettingsData.data.forEach(ele => {
        tempObj = {};
        (tempObj["storeID"] = ele.storeID),
          (tempObj["indexValue"] = ele.indexValueUser),
          (tempObj["referenceStoreID"] = ele.referenceStoreIDUser);
        tempArr.push(tempObj);
      });
      this.$refs["AdminToggle"].toggled = true;
      this.userIndexRefStore = {};
      this.toggleFlag = true;
      this.updateAdminSettingsData({
        data: tempArr,
        all: false
      })
        .then(resp => {
          if (resp.status)
            this.updateAdminSettingsData({
              data: tempArr,
              all: true
            })
              .then(resp => {
                if (resp.status) this.adminSettingsData();
              })
              .catch(err => {
                console.log(err);
              });
        })
        .catch(err => {
          console.log(err);
        });
      this.FetchAdminMetaData()
        .then(res => {
          this.getModifiedDates = res.data;
        })
        .catch(err => {
          console.log(err);
        });

        this.$gtag.event('Clicked_SaveAndPushToAllUserButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Save and Push to all User Button',
      })
    },
    updateDcLevelData() {},
    cellValueChanged(val) {},
    onUploadClick() {
      if (this.fileSelector) {
        this.fileSelector.removeAttribute("type");
        this.fileSelector.removeAttribute("id");
      }
      this.fileSelector = document.createElement("input");
      this.fileSelector.setAttribute("type", "file");
      this.fileSelector.setAttribute("id", "adminSettingsUploadTemplate1");
      var selectDialogueLink = document.createElement("a");
      selectDialogueLink.setAttribute("href", "");
      selectDialogueLink.innerText = "Select File";
      document.body.appendChild(this.fileSelector);
      this.fileSelector.click();
      this.fileSelector.onchange = () => {
        // onChangeFlag = true
        this.selectedFile = document.getElementById(
          "adminSettingsUploadTemplate1"
        ).files[0];
        this.uploadAdminStoreIndex({
          file: this.selectedFile
        })
          .then(res => {
            document.body.removeChild(this.fileSelector);
            this.adminSettingsData();
            document.removeChild(a);
          })
          .catch(err => {
            this.uploadFlag = true;
            this.errorMesg = err.message;
          });
        this.selectedFile = null;
      };

      this.$gtag.event('Clicked_StoreIndexRefrenceMappingUploadButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Store Index Refrence Store mapping Upload Button',
      })
    },
    massUpdateTargetCells(val) {},

    onDownloadClick() {
      this.downloadStoreIndex({
        data: this.getAdminSettingsData.data
      }).then(resp => {
        let a = document.createElement("A");
        a.href = resp.data.url;
        a.download;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.removeChild(a);
      });

      this.$gtag.event('Clicked_StoreIndexRefrenceMappingDownloadButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Store Index Refrence Store mapping Download Button',
      })
    },
    onClosePopOver() {
      this.popoverShow = false;
    },
    onClosePopOver2() {
      this.popoverShow2 = false;
    },
    onShow() {
      this.poissonVal = this.getPoissonDistribution;
      this.finalPoissonVal = this.getPoissonDistribution;
      this.selectedRefStore = null
      this.massRefStore = null

      this.$gtag.event('Clicked_PoisonIndexButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Set Poison Index button',
      })
    },
    onApplyClicked1(onUpdateFunc) {
      this.uploadFlag = false;
      this.popoverShow = false;
      let refStore;
      if (this.selectedRefStore && this.selectedRefStore.value !== "Own Stores") {
        refStore = this.massRefStore.value;
      } else {
        refStore = this.massOwnRefStores;
      }
      onUpdateFunc(this.massIndex, refStore);
      this.massIndex = null;
      this.massRefStore = null;
      refStore = null

      this.$gtag.event('Clicked_IndexRefrenceStoreApplyButton', {
        'event_category': 'Strategy Screen',
        'event_label': 'Click on Index and refrence Store Apply Button',
      })
    }
  },

  computed: {
    ...mapGetters({
      getAdminSettingsData: "CreateAllocation/getAdminSettingsData",
      getLoader: "CreateAllocation/getLoader",
      getLoaderOnReset: "CreateAllocation/getLoaderOnReset",
      getPoissonDistribution: "CreateAllocation/getPoissonDistribution"
    })
  },
  updated() {
    // this.setLoader(false)
  },
  mounted() {
    this.isAdmin = window.localStorage.getItem("admin");
    this.adminSettingsData();
    this.FetchAdminMetaData()
      .then(res => {
        this.getModifiedDates = res.data;
      })
      .catch(err => {
        console.log(err);
      });
    this.fetchPoissonDistribution();
  },
  beforeDestroy() {},

  created() {
    this.$root.$on("GridAPI", this.getRefApi);
    window.addEventListener("beforeunload", handler);
  },
  watch: {
    massIndex(val) {
      if (val) {
        this.disableApply = false;
      }
    }
    // getAdminSettingsData(val) {
    //   this.poissonVal = val.poissonDistribution*100;
    //   this.finalPoissonVal = val.poissonDistribution*100;
    // }
  }
};
</script>

<style scoped>
.paintBrush {
  font-weight: 900;
  color: royalblue;
  padding-left: 5px;
}
.btn-save {
  background-color: rgb(115, 189, 0);
  border-color: rgb(115, 189, 0);
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 100px;
}
.modal-footer .btn {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white !important;
}
.btn-download {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(255, 255, 255) !important;
}
.btn-download:hover {
  color: #71be00 !important;
  border-color: #71be00;
  background-color: rgb(202, 213, 219) !important;
}
</style>